.App {
  --primary:   #4242C7;
  --secondary: #D12C9F;
  --tertiary:  #2BCD62;
  --quarnary:  #0B0B82;

  text-align: center;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  background: var(--primary) linear-gradient(120deg, var(--secondary) 0%, var(--primary) 100%);
  color: var(--tertiary);
  /* background: rgb(130, 100, 170) linear-gradient(120deg, rgb(130, 100, 170) 0%, rgb(39,30,52) 100%); */
}
a{
  color: var(--quarnary80);
}
.App {
  --primary10: hsl(from var(--primary) h s 10);
  --primary20: hsl(from var(--primary) h s 20);
  --primary30: hsl(from var(--primary) h s 30);
  --primary40: hsl(from var(--primary) h s 40);
  --primary50: hsl(from var(--primary) h s 50);
  --primary60: hsl(from var(--primary) h s 60);
  --primary70: hsl(from var(--primary) h s 70);
  --primary80: hsl(from var(--primary) h s 80);
  --primary90: hsl(from var(--primary) h s 90);

  --secondary10: hsl(from var(--secondary) h s 10);
  --secondary20: hsl(from var(--secondary) h s 20);
  --secondary30: hsl(from var(--secondary) h s 30);
  --secondary40: hsl(from var(--secondary) h s 40);
  --secondary50: hsl(from var(--secondary) h s 50);
  --secondary60: hsl(from var(--secondary) h s 60);
  --secondary70: hsl(from var(--secondary) h s 70);
  --secondary80: hsl(from var(--secondary) h s 80);
  --secondary90: hsl(from var(--secondary) h s 90);

  --tertiary10: hsl(from var(--tertiary) h s 10);
  --tertiary20: hsl(from var(--tertiary) h s 20);
  --tertiary30: hsl(from var(--tertiary) h s 30);
  --tertiary40: hsl(from var(--tertiary) h s 40);
  --tertiary50: hsl(from var(--tertiary) h s 50);
  --tertiary60: hsl(from var(--tertiary) h s 60);
  --tertiary70: hsl(from var(--tertiary) h s 70);
  --tertiary80: hsl(from var(--tertiary) h s 80);
  --tertiary90: hsl(from var(--tertiary) h s 90);

  --quarnary10: hsl(from var(--quarnary) h s 10);
  --quarnary20: hsl(from var(--quarnary) h s 20);
  --quarnary30: hsl(from var(--quarnary) h s 30);
  --quarnary40: hsl(from var(--quarnary) h s 40);
  --quarnary50: hsl(from var(--quarnary) h s 50);
  --quarnary60: hsl(from var(--quarnary) h s 60);
  --quarnary70: hsl(from var(--quarnary) h s 70);
  --quarnary80: hsl(from var(--quarnary) h s 80);
  --quarnary90: hsl(from var(--quarnary) h s 90);
}
::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
    pointer-events: auto;
    z-index: 100;
  }
::-webkit-scrollbar-track {
    /* background-color: var(--primary); */
    box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  }
::-webkit-scrollbar-thumb {
    background-color: var(--tertiary);
    /* outline: 1px solid slategrey; */
    border-radius: 0.25em;
  }

@media (prefers-reduced-motion: no-preference) {

}



.alignVertical{
  display: flex;
  flex-direction: column;
}

.alignHorizontal{
  display: flex;
  flex-direction: row;
}

/* Some Rules Regarding Lists/Flex Containers */
/* Items can overflow their div by the maximum of 1em, 12px (example - box shadows) */

.VerticalList{
  display: flex;
  flex-direction: column;
}
.HorizontalList{
  display: flex;
  flex-direction: row;
}
.HorizontalList::-webkit-scrollbar{
  display: none
}
/* need to add content div that is a flex within this to get it to work... 🤮 */
.HorizontalList.Scroll{
  max-width: calc(100% - 0em);
  margin: -1em -1em;
  padding: 1em 1em;
  overflow-x: none;
  overflow-y: scroll;
  mask-image: linear-gradient(to right, transparent 0%, black calc(2em), black calc(100% - 2em), transparent 100%);

}
.HorizontalList.Scroll::-webkit-scrollbar:vertical{
  display: none;
}
.HorizontalList.Scroll::-webkit-scrollbar:horizontal{
  height: .5em;
  display: none;
}
.VerticalList.Scroll{
  max-height: 100%;
  /* width: calc(100% ); */
  overflow-y:scroll;
  overflow-x: hidden;
  margin: -1em -1em;
  padding: 1em 1em;
}
.VerticalList.Scroll::-webkit-scrollbar:vertical {
  width: .5em;
  display: none;
}
.VerticalList.Scroll::-webkit-scrollbar:horizontal{
}
.HorizontalList.Scroll.Repeat{

}

.social-section{
  justify-content: space-around;
}

.end{
  justify-content: end;
}
.start{
  justify-content: start;
}
.ghost{
  display: none;
}
